import { UserOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Dropdown, Layout, Menu } from 'antd';
import React, { FC, useContext, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo-light.svg';
import { AuthContext } from '../context/AuthContext';
import { Routes, Route } from 'react-router-dom';
import { capitalize } from '../lib/utils';
import RolesAuthRoute from './RolesAuthRoute';
import { SITE_ROUTES } from '@/lib/constants';
import { ADMIN_URL } from '@/lib/constants';
import { PiInvoice } from 'react-icons/pi';

import {
	BarChartOutlined,
	ControlOutlined,
	DatabaseOutlined,
	GlobalOutlined,
	HomeOutlined,
	RiseOutlined,
	SettingOutlined,
	TableOutlined,
} from '@ant-design/icons';

type Props = {};

const { Header, Content, Sider } = Layout;

const Home: FC<Props> = (props: Props) => {
	const { user, handleLogout } = useContext(AuthContext);
	const location = useLocation();
	const [siderCollapsed, setSiderCollapsed] = useState(true);

	const items = [
		{
			label: 'Home',
			icon: <HomeOutlined />,
			route: '/',
			show: user?.permission?.rowDataSeePage !== 'yes',
		},
		{
			label: 'Raw Data',
			icon: <DatabaseOutlined />,
			route: '/data',
			show:
				user?.permission?.rowDataSeePage === 'yes' ||
				user?.permission?.rowDataSeeTable === 'yes',
		},
		{
			label: 'Client Services',
			icon: <GlobalOutlined />,
			route: '/client-services',
			show: user?.permission?.clientServicesSeePage === 'yes',
		},
		{
			label: 'Pipeline Details',
			icon: <TableOutlined />,
			route: '/finances-data',
			show: user?.permission?.financeDataSeePage === 'yes',
		},
		{
			label: 'Reconciliation',
			icon: <PiInvoice />,
			route: '/reconciliation-data',
			show: user?.permission?.reconciliationDataSeePage === 'yes',
		},
		{
			label: 'Billings/Investment Summary',
			icon: <BarChartOutlined />,
			route: '/finances',
			show:
				user?.permission?.financeResultsSeePage === 'yes' ||
				user?.permission?.financeResultsSeePage === 'country',
		},
		{
			label: 'Objectives',
			icon: <RiseOutlined />,
			route: '/objectives',
			show: user?.permission?.objetivesSeePage === 'yes',
		},
		{
			label: 'Manage Objectives',
			icon: <SettingOutlined />,
			route: '/manage-objectives',
			show: user?.permission?.manageObjetivesSeePage === 'yes',
		},
		{
			label: 'Admin Panel',
			icon: <ControlOutlined />,
			route: ADMIN_URL,
			show: user?.permission?.seeAdminPanel === 'yes',
			isExternal: true,
		},
	];

	// Manage objectives

	const dropdownContent = [
		{
			key: 'user',
			label: (
				<div className="flex justify-center">
					<Button type="primary" onClick={handleLogout}>
						Logout
					</Button>
				</div>
			),
		},
	];

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Header
				className="flex justify-between shadow-sm"
				style={{ background: 'white', zIndex: 200, height: '70px' }}>
				<Logo className="w-32" />
				<Dropdown menu={{ items: dropdownContent }} placement="bottom" arrow>
					<div className="flex items-center text-slate-900">
						<UserOutlined className="mr-2 text-xl" />
						{user?.name}
					</div>
				</Dropdown>
			</Header>
			<Layout>
				<Sider
					width={200}
					collapsible
					theme="light"
					collapsed={siderCollapsed}
					onCollapse={(value) => setSiderCollapsed(value)}
					className="site-layout-background">
					<Menu
						mode="inline"
						theme="light"
						defaultSelectedKeys={['/data']}
						selectedKeys={[location.pathname]}
						style={{ height: '100%', borderRight: 0 }}>
						{items.map(
							(item, index) =>
								item.show &&
								item.route && (
									<Menu.Item key={index} icon={item.icon}>
										{item.isExternal ? (
											<a
												href={item.route}
												target="_blank"
												rel="noopener noreferrer">
												{item.label}
											</a>
										) : (
											<Link to={item.route}>{item.label}</Link>
										)}
									</Menu.Item>
								)
						)}
					</Menu>
				</Sider>
				<Layout style={{ padding: '0 30px 24px' }}>
					<Breadcrumb style={{ margin: '16px 0' }}>
						<Breadcrumb.Item>Home</Breadcrumb.Item>
						<Breadcrumb.Item>
							{capitalize(
								location.pathname.replace('/', '').replace('-', ' ')
							) === 'Finances data'
								? 'Pipeline Details'
								: capitalize(
										location.pathname.replace('/', '').replace('-', ' ')
								  ) === 'Finances'
								? 'Billings/Investment Summary'
								: capitalize(
										location.pathname.replace('/', '').replace('-', ' ')
								  )}
						</Breadcrumb.Item>
					</Breadcrumb>
					<Content
						className="site-layout-background"
						style={{
							margin: 0,
							minHeight: 280,
						}}>
						<Routes>
							{SITE_ROUTES.map((route) => (
								<Route
									key={route.route}
									path={route.route}
									element={
										<RolesAuthRoute permissions={route.allowedPermission}>
											{route.component}
										</RolesAuthRoute>
									}></Route>
							))}
						</Routes>
					</Content>
				</Layout>
			</Layout>
		</Layout>
	);
};

export default Home;
