// here
import { useQuery } from '@apollo/client';
import { Space, Table, TableColumnsType } from 'antd';
import { FC, useContext, useState, useEffect } from 'react';
import { Report } from '@/interfaces/Report';
import {
	ReportsData,
	ReportsQueryVariables,
	REPORTS_QUERY_VAR,
} from '@/lib/reportsApi';
import { formatMoney } from '@/utils/currency';
import InputDataModal from './InputDataModal';
import ImportCSVModal from './ImportCSVModal';
import ExportCSVButton from './ExportCSVButton';
import { FiltersContext } from '@/context/FiltersContext';
import { AuthContext } from '../../context/AuthContext';
import dayjs from 'dayjs';
import { Navigate } from 'react-router-dom';

import './style.css';

type Props = {};

const Data: FC<Props> = (props: Props) => {
	const { user } = useContext(AuthContext);
	const [queryVariables, setQueryVariables] = useState<any>({
		take: 15,
		skip: 0,
		filters: { AND: [] },
		// sorter: { id: 'asc' },
		sorter: [{ startDate: 'desc' }, { gluCampaignID: 'desc' }],
	});

	const { loading, data, refetch } = useQuery<
		ReportsData,
		ReportsQueryVariables
	>(REPORTS_QUERY_VAR, {
		variables: queryVariables,
	});
	const { countries, agencies, salespeople, clients } =
		useContext(FiltersContext);

	const [pageSize, setPageSize] = useState<number>(15);
	const [showMessage, setShowMessage] = useState<boolean>(false);
	const [showTable, setShowTable] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const tableColumns: TableColumnsType<Report> = [
		{
			key: 'gluCampaignID',
			dataIndex: 'gluCampaignID',
			title: 'Campaign ID',
		},
		{
			key: 'salesperson',
			dataIndex: 'salesperson',
			title: 'Salesperson',
			render: (salesperson) => (salesperson ? salesperson.name : 'N/A'),
			sortDirections: ['ascend', 'descend'],
			filters: [...salespeople]
				.sort((a, b) => a.name.localeCompare(b.name))
				.map((e) => ({
					text: e.name,
					value: e.name,
				})),
			onFilter: (value, record) => value === record.salesperson.name,
			filterSearch: true,
			ellipsis: true,
		},
		{
			key: 'country',
			dataIndex: 'country',
			title: 'Country',
			filters: [...countries]
				.sort((a, b) => a.name.localeCompare(b.name))
				.map((c) => ({
					text: c.name,
					value: c.name,
				})),
			render: (text) => text.name,
			filterSearch: true,
			ellipsis: true,
		},
		{
			key: 'agency',
			dataIndex: 'agency',
			title: 'Agency',
			filters: [...agencies]
				.sort((a, b) => a.name.localeCompare(b.name))
				.map((c) => ({
					text: c.name,
					value: c.name,
				})),
			render: (text) => text.name,
			filterSearch: true,
			ellipsis: true,
		},
		{
			key: 'client',
			dataIndex: 'client',
			title: 'Client',
			filters: [...clients]
				.sort((a, b) => a.name.localeCompare(b.name))
				.map((c) => ({
					text: c.name,
					value: c.name,
				})),
			render: (text) => text.name,
			filterSearch: true,
			ellipsis: true,
		},
		{
			key: 'billingLC',
			dataIndex: 'billing',
			title: 'Billing LC',
			render: (billing, record) =>
				formatMoney(billing, 'en-US', record.currency),
			sortDirections: ['ascend', 'descend'],
			sorter: true,
		},
		{
			key: 'billingUSD',
			dataIndex: 'billing',
			title: 'Billing USD',
			render: (billing, record) => formatMoney(billing * record.exchangeRate),
		},
		{
			key: 'investmentLC',
			dataIndex: 'investment',
			title: 'Investment LC',
			render: (investment, record) =>
				formatMoney(investment, 'en-US', record.currency),
			sorter: (a, b) => a.investment - b.investment,
		},
		{
			key: 'investmentUSD',
			dataIndex: 'investment',
			title: 'Investment USD',
			render: (investment, record) =>
				formatMoney(investment * record.exchangeRate),
		},
		{
			key: 'budgetLC',
			dataIndex: 'budget',
			title: 'Budget LC',
			render: (budget, record) => formatMoney(budget, 'en-US', record.currency),
			sorter: (a, b) => a.budget - b.budget,
		},
		{
			key: 'budgetUSD',
			dataIndex: 'budget',
			title: 'Budget USD',
			render: (budget, record) => formatMoney(budget * record.exchangeRate),
		},
		{
			key: 'billingMonth',
			dataIndex: 'billingMonth',
			width: 130,
			title: 'Order month',
			render: (value: any) => {
				if (value) {
					const formattedDate = dayjs(value);
					const currentYear = dayjs().year();
					const year =
						formattedDate.year() === 2001 ? currentYear : formattedDate.year();
					return formattedDate.format(`MMM-${year}`);
				} else {
					return 'N/A';
				}
			},
		},
	];

	const handleOnChange = (pagination: any, filters: any, sorter: any) => {
		setPageSize(pagination.pageSize);
		const f = Object.entries(filters).map((filter: any) => {
			if (!filter[1]) return [];
			const key = filter[0];
			return {
				OR: filter[1].map((v: string) => ({ [key]: { name: { equals: v } } })),
			};
		});
		setQueryVariables({
			take: pagination.pageSize,
			skip: pagination.pageSize * (pagination.current - 1),
			filters: { AND: f },
			sorter: sorter.order
				? { [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc' }
				: // : { id: 'asc' },
				  [{ startDate: 'desc' }, { gluCampaignID: 'desc' }],
		});
	};

	const totalItems = data?.count || 0;
	const totalPages = Math.ceil(totalItems / pageSize);

	const fetchDataForPage = async (page: number): Promise<Report[]> => {
		const variables = { ...queryVariables, skip: (page - 1) * pageSize };
		const { data } = await refetch(variables);
		return data?.items || [];
	};

	useEffect(() => {
		setShowTable(true);
	}, []);

	const handlePageChange = async (page: number) => {
		setCurrentPage(page);
		const variables = {
			...queryVariables,
			take: pageSize,
			skip: pageSize * (page - 1),
			filters: { AND: [[], [], [], [], []] },
		};
		await refetch(variables);
	};

	const resetToFirstPage = () => {
		handlePageChange(1);
	};

	return (
		<div>
			{user?.permission?.rowDataSeePage === 'yes' ||
			user?.permission?.rowDataSeeTable === 'yes' ? (
				<>
					<div className="flex" style={{ justifyContent: 'space-between' }}>
						<div className="flex">
							<Space>
								<InputDataModal />
								<ImportCSVModal refetch={refetch} />
							</Space>
						</div>
						<div>
							{user?.permission?.rowDataExportCsv === 'yes' ? (
								<ExportCSVButton
									data={data?.items || []}
									totalPages={totalPages}
									fetchDataForPage={fetchDataForPage}
									setShowMessage={setShowMessage}
									setShowTable={setShowTable}
									resetToFirstPage={resetToFirstPage}
								/>
							) : (
								''
							)}
						</div>
					</div>

					{showMessage && (
						<div className="mt-4 mb-4 rounded-lg bg-gray-400 py-8 px-4 pt-32 pb-32 text-left font-bold text-white">
							<div className="flex items-center justify-center">
								<div className="mr-4 flex-shrink-0">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="mr-2 h-16 w-16 animate-bounce"
										viewBox="0 0 16 16">
										<path
											fill="none"
											stroke="currentColor"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="1.5"
											d="M10.75 11.25c4.5 0 4.5-5.5 0-5.5h-9v5c0 5 8.5 5 8.5 0v-5m-1.5-4v1.5m-3-1.5v1.5m-3-1.5v1.5"
										/>
									</svg>
									<span className="sr-only">Loading...</span>
								</div>
								<div>
									{user?.name && (
										<h1 className="mb-2 text-4xl">
											Hi {user.name.split(' ')[0]}! Take a coffee break while
											<br></br> we process the request for the data.
										</h1>
									)}
									<h3 className="text-1xl mb-0 mt-6">
										We are currently processing a total of
										<span className="ml-2 mr-2 rounded-lg bg-red-500 p-1 text-base font-bold leading-4 text-white opacity-100">
											{totalPages * 10}
										</span>
										records.
									</h3>
									<h3 className="text-1xl mb-12 mt-1">
										This process may take some time.
									</h3>
								</div>
							</div>
						</div>
					)}

					{showTable && (
						<Table
							columns={tableColumns}
							rowKey="id"
							dataSource={data?.items}
							rowClassName={(record: Report, index) =>
								index % 2 === 0
									? 'bg-white text-[12px]'
									: 'bg-gray-200 text-[12px]'
							}
							loading={loading && !data?.items}
							className="raw-data-table"
							bordered
							size="middle"
							pagination={{
								current: currentPage,
								pageSize,
								total: data?.count,
								position: ['bottomRight'],
								pageSizeOptions: [10, 15, 20, 30],
							}}
							onChange={handleOnChange}></Table>
					)}
				</>
			) : (
				<Navigate to="/" />
			)}
		</div>
	);
};

export default Data;
