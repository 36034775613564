import { Report } from '@/interfaces/Report';
import {
	ReportsData,
	ReportsQueryVariables,
	REPORTS_QUERY_VAR,
} from '@/lib/reportsApi';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { FC, useContext, useState } from 'react';
import PlatformSubtable from './PlatformSubtable';

import { AuthContext } from '../../../context/AuthContext';

import './style.css';
import { FiltersContext } from '@/context/FiltersContext';
import {
	Button,
	Checkbox,
	DatePicker,
	Divider,
	Form,
	Select,
	Space,
	Table,
	TableColumnsType,
	Tag,
	Tooltip,
} from 'antd';
import { formatMoney } from '@/utils/currency';
import {
	calculateSpend,
	checkCampaignStatus,
	styleCampaignStatus,
	setWarning,
	calculateCampaignBudget,
	getDefaultColumns,
	getTableColumns,
	getSelectItems,
} from './utils';
import {
	DeleteOutlined,
	ExclamationCircleOutlined,
	FilterOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import { searchFilter } from '@/routes/FinancesDataPage/utils';
import dayjs from 'dayjs';
import { capitalize } from '@/lib/utils';

import ClientExcelData from './ClientExcelData';
import CampaignActions from '@/routes/FinancesDataPage/CampaignActions';
import { ObjectiveSummary } from '../../../lib/targetsApi';

import { useNavigate } from 'react-router-dom';

type Props = {
	totalPages: number;
};

const statusFilters: Record<string, any> = {
	paused: { platforms: { every: { status: { equals: 'paused' } }, some: {} } },
	pending: {
		platforms: { every: { status: { equals: 'pending' } }, some: {} },
	},
	finished: {
		platforms: { every: { status: { equals: 'finished' } }, some: {} },
	},
	// active: { platforms: { some: { status: { equals: 'active' } } } },
	active: { platforms: { every: { status: { equals: 'active' } }, some: {} } },
	// mixed: {
	//   NOT: {
	//     OR: [
	//       { platforms: { every: { status: { equals: 'finished' } } } },
	//       { platforms: { every: { status: { equals: 'paused' } } } },
	//       { platforms: { every: { status: { equals: 'pending' } } } },
	//       { platforms: { some: { status: { equals: 'active' } } } },
	//     ],
	//   },
	// },
	mixed: {
		AND: [
			{
				NOT: [
					{ platforms: { every: { status: { equals: 'active' } } } },
					{ platforms: { every: { status: { equals: 'paused' } } } },
					{ platforms: { every: { status: { equals: 'pending' } } } },
					{ platforms: { every: { status: { equals: 'finished' } } } },
				],
			},
			{
				OR: [
					{ platforms: { some: { status: { equals: 'paused' } } } },
					{ platforms: { some: { status: { equals: 'pending' } } } },
					{ platforms: { some: { status: { equals: 'finished' } } } },
					{ platforms: { some: { status: { equals: 'active' } } } },
				],
			},
		],
	},
};

const relationshipKeys = [
	'agency',
	'country',
	'client',
	'market',
	'accountManager',
	'planner',
];

const ClientServicesTable: FC<Props> = (props: Props) => {
	const filterStatus = ['active', 'paused', 'pending', 'finished', 'mixed'];

	const [queryVariables, setQueryVariables] = useState<any>({
		take: 15,
		skip: 0,
		filters: { AND: [[], [], [], [], [], []] },
		sorter: [{ startDate: 'desc' }, { gluCampaignID: 'desc' }],
	});
	const [dateFilter, setDateFilter] = useState<any>();

	const { loading, data, refetch } = useQuery<
		ReportsData,
		ReportsQueryVariables
	>(REPORTS_QUERY_VAR, {
		variables: queryVariables,
		fetchPolicy: 'network-only',
	});

	const { agencies, countries, clients, accountManagers, planners } =
		useContext(FiltersContext);

	const { user } = useContext(AuthContext);

	const [pageSize, setPageSize] = useState<number>(15);

	const items = getSelectItems();
	const [filterColumns, setFilterColumns] = useState(getDefaultColumns);
	const [columnsTable, setTableColumns] = useState(getDefaultColumns);
	const [isChecked, setIsChecked] = useState(false);
	const [isTableShown, setShowTable] = useState(true);
	const [open, setOpen] = useState(false);
	const [isSelect, setIsSelect] = useState(false);

	const [showMessageStyle, setShowMessageStyle] = useState<React.CSSProperties>(
		{
			display: 'none',
		}
	);
	const [showTableStyle, setShowTableStyle] = useState<React.CSSProperties>({
		display: 'block',
	});

	const [currentPage, setCurrentPage] = useState<number>(1);

	const tableColumns: TableColumnsType<any> = [
		{
			key: 'gluCampaignID',
			dataIndex: 'gluCampaignID',
			title: 'GID',
			width: 120,
			fixed: 'left',
			filterSearch: true,
			filterIcon: <SearchOutlined />,
			filterDropdown: searchFilter,
		},
		{
			key: 'campaign',
			dataIndex: 'campaign',
			title: 'Campaign',
			width: 200,
			filterIcon: <SearchOutlined />,
			filterDropdown: searchFilter,
			sorter: (a, b) => a.campaign.localeCompare(b.campaign),
			render: (text) => text,
		},
		{
			key: 'agency',
			dataIndex: 'agency',
			title: 'Agency',
			width: 110,
			filters: [...agencies]
				.sort((a, b) => a.name.localeCompare(b.name))
				.map((c) => ({
					text: c.name,
					value: c.name,
				})),
			render: (text) => text.name,
			filterSearch: true,
			ellipsis: true,
		},
		{
			key: 'country',
			dataIndex: 'country',
			title: 'Country',
			width: 110,
			filters: [...countries]
				.sort((a, b) => a.name.localeCompare(b.name))
				.map((c) => ({
					text: c.name,
					value: c.name,
				})),
			render: (text) => text.name,
			filterSearch: true,
			ellipsis: true,
		},
		{
			key: 'market',
			dataIndex: 'market',
			title: 'Market',
			width: 100,
			filters: [...countries]
				.sort((a, b) => a.name.localeCompare(b.name))
				.map((c) => ({
					text: c.name,
					value: c.name,
				})),
			render: (market) =>
				market.length > 0 ? market.map((m: any) => m.name).join(', ') : 'N/A',
			filterSearch: true,
			ellipsis: true,
		},
		{
			key: 'client',
			dataIndex: 'client',
			title: 'Client',
			width: 100,
			filters: [...clients]
				.sort((a, b) => a.name.localeCompare(b.name))
				.map((c) => ({
					text: c.name,
					value: c.name,
				})),
			render: (text) => text.name,
			filterSearch: true,
			ellipsis: true,
		},
		{
			key: 'accountManager',
			dataIndex: 'accountManager',
			title: 'Account Manager',
			width: 100,
			render: (accountManager) => accountManager?.name || 'N/A',
			filters: [...accountManagers]
				.sort((a, b) => a.name.localeCompare(b.name))
				.map((e) => ({
					text: e.name,
					value: e.name,
				})),
			filterSearch: true,
			ellipsis: true,
		},
		{
			key: 'planner',
			dataIndex: 'planner',
			title: 'Planner',
			width: 120,
			ellipsis: true,
			render: (planner) => planner?.name || 'N/A',
			filters: [...planners]
				.sort((a, b) => a.name.localeCompare(b.name))
				.map((e) => ({
					text: e.name,
					value: e.name,
				})),
			filterSearch: true,
		},
		{
			key: 'status',
			dataIndex: 'platforms',
			title: 'Status',
			width: 100,
			render: (platforms: any[], record: any) => {
				const needsWarning = setWarning(record);
				return (
					<>
						{checkCampaignStatus(platforms)}{' '}
						{needsWarning && (
							<Tooltip title="By date, this campaign should not be active.">
								{' '}
								<ExclamationCircleOutlined
									style={{ fontWeight: 'bolder', color: 'red' }}
								/>{' '}
							</Tooltip>
						)}
					</>
				);
			},
			filters: filterStatus.map((status) => ({
				text: capitalize(status),
				value: status,
			})),
			filterSearch: true,
		},
		{
			key: 'startDate',
			dataIndex: 'startDate',
			title: 'Start Date',
			width: 100,
			sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix(),
			render: (date) => moment(date).format('ll'),
			ellipsis: true,
		},
		{
			key: 'endDate',
			dataIndex: 'endDate',
			title: 'End Date',
			width: 120,
			render: (date) => moment(date).format('ll'),
			sorter: (a, b) => moment(a.endDate).unix() - moment(b.endDate).unix(),
		},
		{
			key: 'campaignDays',
			dataIndex: 'campaignDays',
			title: 'Campaign days',
			width: 80,
			render: (campaignDays) =>
				campaignDays !== null ? `${campaignDays} days` : 'N/A',
			ellipsis: true,
		},
		{
			key: 'activeDays',
			dataIndex: 'activeDays',
			title: 'Active Days',
			width: 80,
			render: (activeDays) =>
				activeDays !== null ? `${activeDays} days` : 'N/A',
			ellipsis: true,
		},
		{
			key: 'budgetLC',
			dataIndex: 'budget',
			title: 'Budget',
			width: 80,
			// render: (budget, record) => formatMoney(budget, 'en-US', record.currency),
			render: (budget, record) => formatMoney(budget, 'en-US'),
			sorter: (a, b) => a.budget - b.budget,
		},
		{
			key: 'investment',
			dataIndex: 'platforms',
			title: 'Investment',
			width: 100,
			render: (platforms: any[], record) => {
				return formatMoney(record.investment);
			},
		},
		{
			key: 'spend',
			dataIndex: 'platforms',
			title: <Tooltip title="Spend">Spend</Tooltip>,
			width: 80,
			render: (platforms: any[]) => {
				const total = platforms.reduce(
					(prev, current) => prev + calculateSpend(current),
					0
				);
				return formatMoney(total);
			},
		},
		{
			key: 'objectiveCost',
			dataIndex: 'objectiveCost',
			title: 'Benchmark Cost',
			width: 85,
			render: (objectiveCost) =>
				objectiveCost !== null ? objectiveCost : 'N/A',
			ellipsis: true,
		},
		// {
		// 	key: 'totalDelivery',
		// 	dataIndex: 'platforms',
		// 	title: <Tooltip title="Total Delivery">T Delivery</Tooltip>,
		// 	width: 80,
		// 	render: (platforms: any[], record) => {
		// 		if (platforms.length === 0) {
		// 			return 'N/A';
		// 			// return record.budget;
		// 			//  spend / budget en porcentaje
		// 			// no usar investment
		// 		}

		// 		const totalObjectiveSpend = platforms.reduce(
		// 			(sum, p) => sum + (p.objectiveSpend || 0),
		// 			0
		// 		);
		// 		const totalObjectiveValue = platforms.reduce(
		// 			(sum, p) => sum + (p.objectiveValue || 0),
		// 			// (sum, p) => sum + (p.budget || 0),
		// 			0
		// 		);

		// 		if (totalObjectiveValue === 0) {
		// 			return 'N/A';
		// 		}

		// 		const percentage = Math.round(
		// 			(totalObjectiveSpend / totalObjectiveValue) * 100
		// 		);

		// 		return `${percentage}%`;
		// 	},
		// 	ellipsis: true,
		// },
		{
			key: 'totalDelivery',
			dataIndex: 'platforms',
			title: <Tooltip title="Total Delivery">T Delivery</Tooltip>,
			width: 80,
			render: (platforms: any[], record) => {
				const spend = platforms.reduce(
					(prev, current) => prev + calculateSpend(current),
					0
				);

				const budget = record.budget || 0;

				const percentage =
					budget > 0 ? Math.round((spend / budget) * 100) : NaN;

				return percentage > 0 ? `${percentage}%` : 'N/A';
			},
			ellipsis: true,
		},
		{
			key: 'actions',
			dataIndex: 'gluCampaignID',
			title: 'Actions',
			width: 70,
			fixed: 'right',
			align: 'center',
			render: (value, record) => (
				<CampaignActions report={record} refetch={refetch} />
			),
		},
	];

	const handleOnChange = (pagination: any, filters: any, sorter: any) => {
		setPageSize(pagination.pageSize);
		const f = Object.entries(filters).map((filter: any) => {
			if (!filter[1]) return [];
			const key = filter[0];
			return {
				OR: filter[1].map((v: string) => {
					if (relationshipKeys.includes(key)) {
						return { [key]: { name: { equals: v } } };
					} else if (key === 'status') {
						return statusFilters[v];
					} else {
						return { [key]: { contains: v, mode: 'insensitive' } };
					}
				}),
			};
		});
		if (dateFilter) {
			f.push({
				OR: [
					{
						AND: [
							{ startDate: { gte: dateFilter.start } },
							{ startDate: { lte: dateFilter.end } },
						],
					},
				],
			});
		}
		setQueryVariables({
			take: pagination.pageSize,
			skip: pagination.pageSize * (pagination.current - 1),
			filters: { AND: f },
			// sorter: { startDate: 'desc' },
			sorter: [{ startDate: 'desc' }, { gluCampaignID: 'desc' }],
		});
	};

	const handleDateChange = (values: any) => {
		setDateFilter({
			start: values[0].format('YYYY-MM-DD'),
			end: values[1].format('YYYY-MM-DD'),
		});
		const newVars = { ...queryVariables };
		const filters = newVars.filters.AND;
		filters[4] = {
			AND: [
				{ startDate: { gte: values[0].format('YYYY-MM-DD') } },
				{ startDate: { lte: values[1].format('YYYY-MM-DD') } },
			],
		};
		newVars.filters.AND = filters;

		setQueryVariables(newVars);
		refetch();
	};

	const navigate = useNavigate();

	const handleReset = () => {
		setFilterColumns(getDefaultColumns());
		setTableColumns(getDefaultColumns());
		setDateFilter(undefined);
		setIsChecked(false);
		setShowTable(true);

		setQueryVariables({
			take: 15,
			skip: 0,
			filters: { AND: [[], [], [], [], []] },
			sorter: [{ gluCampaignID: 'desc' }],
		});

		setCurrentPage(1);
		// setTimeout((): void => {
		// 	refetch();
		// 	navigate(0);
		// }, 100);

		navigate(0);
		refetch();
	};

	const onCampaignStatus = (record: Report) => {
		return styleCampaignStatus(record.platforms);
	};

	const handleSelect = (value: any) => {
		setFilterColumns([...filterColumns, value]);
	};

	const handleDeselect = (value: any) => {
		const temp = [...filterColumns];
		temp.forEach((col: string, index: number) => {
			if (col === value) {
				temp.splice(index, 1);
			}
		});
		setFilterColumns(temp);
		setIsChecked(false);
	};

	const handleFocus = () => {
		setOpen(true);
	};

	const applyFilter = () => {
		setTableColumns(filterColumns);
		setIsSelect(true);
		setShowTable(filterColumns.length > 0 ? true : false);
		setOpen(false);
	};

	const selectAll = (value: any) => {
		const allOptions: any = [];

		if (!isChecked) {
			items.map((item) => allOptions.push(item.value));
			setIsChecked(true);
		} else {
			setIsChecked(false);
		}

		setFilterColumns(allOptions);
	};

	let filterTableColumns = getTableColumns(
		columnsTable,
		tableColumns,
		isSelect
	);

	const totalPages = Math.ceil((data?.count || 0) / pageSize);

	return (
		<>
			<div className="flex">
				<Form.Item label="Date range" className="mb-2">
					<DatePicker.RangePicker
						onChange={handleDateChange}
						value={
							dateFilter === undefined
								? dateFilter
								: [dayjs(dateFilter.start), dayjs(dateFilter.end)]
						}
					/>
				</Form.Item>
				<Tooltip
					title={
						'Click on the tags to select wich columns are shown or hidden'
					}>
					<span style={{ padding: '9px' }}> Columns filter: </span>
				</Tooltip>
				<Select
					mode="multiple"
					style={{ width: '20%' }}
					placeholder="Column Filter"
					value={filterColumns}
					maxTagCount="responsive"
					onDeselect={handleDeselect}
					onFocus={handleFocus}
					onSelect={handleSelect}
					open={open}
					tagRender={(items) => (
						<Tag className="ant-select-selection-item" closable={false}>
							{items.label}
						</Tag>
					)}
					options={items}
					dropdownRender={(menu) => (
						<>
							{menu}
							<Divider style={{ margin: '8px 0' }} />
							<Space style={{ padding: '0 8px 4px', width: '100%' }}>
								<Button
									type="primary"
									onClick={applyFilter}
									style={{ width: '100%' }}
									icon={<FilterOutlined />}>
									Apply filter
								</Button>
								<Checkbox onChange={selectAll} checked={isChecked}>
									Select All
								</Checkbox>
							</Space>
						</>
					)}
				/>
				<Button
					icon={<DeleteOutlined />}
					type="ghost"
					className="ml-1"
					onClick={handleReset}
				/>

				<div className="flex-grow">
					<div className="float-right">
						{user?.permission?.clientServicesExportCsv === 'yes' ? (
							<ClientExcelData
								data={data}
								queryVariables={queryVariables}
								refetch={refetch}
								setShowMessageStyle={setShowMessageStyle}
								setShowTableStyle={setShowTableStyle}
								filterColumns={filterColumns}
							/>
						) : (
							''
						)}
					</div>
				</div>
			</div>

			<div style={showMessageStyle}>
				<div className="mt-4 mb-4 rounded-lg bg-gray-400 py-8 px-4 pt-32 pb-32 text-left font-bold text-white">
					<div className="flex items-center justify-center">
						<div className="mr-4 flex-shrink-0">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="mr-2 h-16 w-16 animate-bounce"
								viewBox="0 0 16 16">
								<path
									fill="none"
									stroke="currentColor"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="1.5"
									d="M10.75 11.25c4.5 0 4.5-5.5 0-5.5h-9v5c0 5 8.5 5 8.5 0v-5m-1.5-4v1.5m-3-1.5v1.5m-3-1.5v1.5"
								/>
							</svg>
							<span className="sr-only">Loading...</span>
						</div>
						<div>
							{user?.name && (
								<h1 className="mb-2 text-4xl">
									Hi {user.name.split(' ')[0]}! Take a coffee break while
									<br></br> we process the request for the data.
								</h1>
							)}
							<h3 className="text-1xl mb-0 mt-6">
								We are currently processing a total of
								<span className="ml-2 mr-2 rounded-lg bg-red-500 p-1 text-base font-bold leading-4 text-white opacity-100">
									{totalPages * 10}
								</span>
								records.
							</h3>
							<h3 className="text-1xl mb-12 mt-1">
								This process may take some time.
							</h3>
						</div>
					</div>
				</div>
			</div>

			<div style={showTableStyle}>
				<Table
					expandable={{
						expandedRowRender: (record) => (
							<PlatformSubtable
								refetchReports={refetch}
								platforms={record.platforms}
								id={record.id}
								startDate={record.startDate}
								endDate={record.endDate}
								campaignDays={record.campaignDays}
								activeDays={record.activeDays}
								timePeriod={record.timePeriod}
								budget={record.budget}
							/>
						),
						expandedRowClassName: (record: Report, index) =>
							index % 2 === 0
								? 'bg-white text-[12px]'
								: 'bg-gray-200 text-[12px]',
					}}
					rowKey="id"
					columns={filterTableColumns}
					dataSource={data?.items}
					rowClassName={onCampaignStatus}
					className={
						isTableShown
							? 'client-service-table'
							: 'client-service-table, hidden'
					}
					size="middle"
					loading={loading}
					onChange={handleOnChange}
					pagination={{
						current: currentPage,
						pageSize,
						total: data?.count,
						position: ['bottomRight'],
						pageSizeOptions: [10, 15, 20, 30],
						onChange: (page) => setCurrentPage(page),
					}}
					scroll={{ x: 1300 }}
				/>
			</div>
		</>
	);
};

export default ClientServicesTable;
