import { FiltersContext } from '@/context/FiltersContext';
import {
	SalespersonTargetQueryData,
	SalespersonTargetQueryVariables,
	SALESPERSON_TARGET_QUERY,
	Target,
} from '@/lib/targetsApi';
import { formatMoney } from '@/utils/currency';
import { DeleteOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, DatePicker, Form, Table, TableColumnsType } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useContext, useState } from 'react';
import AddTargetModal from './AddTargetModal';
import EditTargetModal from './EditTargetModal';

import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

type Props = {};

const ManageTargets = (props: Props) => {
	const [selectedYear, setSelectedYear] = useState<string | undefined>('2023');

	const { data, loading, refetch } = useQuery<
		SalespersonTargetQueryData,
		SalespersonTargetQueryVariables
	>(SALESPERSON_TARGET_QUERY, { variables: { year: selectedYear } });

	const { countries, agencies, salespeople } = useContext(FiltersContext);

	const columns: TableColumnsType<Target> = [
		{
			key: 'salesperson',
			dataIndex: 'salesperson',
			title: 'Salesperson',
			render: (salesperson) => (salesperson ? salesperson.name : 'N/A'),
			sortDirections: ['ascend', 'descend'],
			filters: salespeople.map((e) => ({
				text: e.name,
				value: e.name,
			})),
			onFilter: (value, record) => value === record.salesperson.name,
			filterSearch: true,
			ellipsis: true,
		},
		{
			key: 'country',
			dataIndex: 'country',
			title: 'Country',
			render: (country) => (country ? country.name : 'N/A'),
			sortDirections: ['ascend', 'descend'],
			filters: countries.map((e) => ({
				text: e.name,
				value: e.name,
			})),
			onFilter: (value, record) => value === record.country.name,
			filterSearch: true,
			ellipsis: true,
		},
		{
			key: 'agency',
			dataIndex: 'agency',
			title: 'Agency',
			render: (agency) => (agency ? agency.name : 'N/A'),
			sortDirections: ['ascend', 'descend'],
			filters: agencies.map((e) => ({
				text: e.name,
				value: e.name,
			})),
			onFilter: (value, record) => value === record.agency.name,
			filterSearch: true,
			ellipsis: true,
		},
		{
			key: 'targetUSD',
			dataIndex: 'target',
			title: 'Objective USD',
			render: (objective, record) =>
				formatMoney(objective * record.exchangeRate),
			sortDirections: ['ascend', 'descend'],
			sorter: (a, b) => a.target * a.exchangeRate - b.target * b.exchangeRate,
		},
		{
			key: 'target',
			dataIndex: 'target',
			title: 'Objective',
			render: (billing, record) =>
				formatMoney(billing, 'en-US', record.currency),
			sortDirections: ['ascend', 'descend'],
			sorter: (a, b) => a.target - b.target,
		},
		{
			key: 'year',
			dataIndex: 'year',
			title: 'Year',
			sorter: (a, b) => parseInt(a.year) - parseInt(b.year),
		},
		{
			key: 'actions',
			dataIndex: 'gluCampaignID',
			title: 'Actions',
			width: 70,
			align: 'center',
			render: (value, record) => {
				return (
					<>
						<EditTargetModal refetch={refetch} record={record} />
					</>
				);
			},
		},
	];

	const handleYearChange = (value: Dayjs | null) => {
		setSelectedYear(value?.year().toString() || '2023');
	};

	const handleReset = () => {
		setSelectedYear(undefined);
	};

	const { user } = useContext(AuthContext);

	return (
		<>
			{/* {user?.permission?.rowDataSeeTable !== 'yes' ||
				user?.permission?.clientServicesSeePage !== 'yes' ||
				!(user?.permission?.financeResultsSeePage in ['yes', 'country']) ||
				user?.permission?.financeDataSeePage !== 'yes' ||
				user?.permission?.reconciliationDataSeePage !== 'yes' ||
				user?.permission?.objetivesSeePage !== 'yes' ||
				user?.permission?.manageObjetivesSeePage !== 'yes' ||
				user?.permission?.seeAdminPanel !== 'yes' ? (
					<Navigate to="/" />
				) : null} */}
			{user?.permission?.manageObjetivesSeePage === 'yes' ? (
				<>
					<div className="my-2 flex justify-between">
						<div className="flex">
							<h1 className="my-0 text-xl">Manage Objectives</h1>
							<Form.Item label="Date range" className="mb-0 ml-2">
								<DatePicker
									picker="year"
									value={
										selectedYear !== undefined
											? dayjs(selectedYear)
											: selectedYear
									}
									onChange={handleYearChange}
								/>
							</Form.Item>
							<Button
								icon={<DeleteOutlined />}
								type="ghost"
								className="ml-1"
								onClick={handleReset}
							/>
						</div>
						<AddTargetModal refetch={refetch} />
					</div>
					<Table
						bordered
						rowKey="id"
						className="shadow"
						loading={loading}
						columns={columns}
						pagination={false}
						rowClassName={'text-[12px]'}
						size="middle"
						dataSource={data?.targets}></Table>
				</>
			) : (
				<Navigate to="/" />
			)}
		</>
	);
};

export default ManageTargets;
