import { generateGIDPrefix } from '@/lib/utils';
import { CopyOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Input, message, Tooltip } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { gql } from 'graphql.macro';
import { FC, useEffect } from 'react';

type Props = {
  form: FormInstance<any>;
};

interface ReportsCountData {
  reportsCount: number;
}

interface ReportsCountVariables {
  gid: string;
}

const GLU_CAMPAIGN_ID_COUNT_QUERY = gql`
  query GLU_CAMPAIGN_ID($gid: String!) {
    reportsCount(where: { gluCampaignID: { contains: $gid } })
  }
`;

const CampaignIdField: FC<Props> = (props: Props) => {
  const gid = generateGIDPrefix();
  const { data } = useQuery<ReportsCountData, ReportsCountVariables>(
    GLU_CAMPAIGN_ID_COUNT_QUERY,
    { variables: { gid } }
  );

  useEffect(() => {
    if (data) {
      props.form.setFieldsValue({
        gluCampaignID: `${gid}.${data?.reportsCount + 1}`,
      });
    }
  }, [data, gid, props.form]);

  const handleCopyToCliboard = () => {
    navigator.clipboard.writeText(`${gid}.${data && data?.reportsCount + 1}`);
    message.success('Campaign ID copied to Clipboard!');
  };

  return (
    <Input.Group compact>
      <Input
        disabled
        style={{ width: 'calc(100% - 32px)' }}
        value={`${gid}.${data && data?.reportsCount + 1}`}
      />
      <Tooltip title="Copy campaign ID">
        <Button icon={<CopyOutlined />} onClick={handleCopyToCliboard} />
      </Tooltip>
    </Input.Group>
  );
};

export default CampaignIdField;
