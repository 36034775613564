import { Form, InputNumber, Select } from 'antd';
import { FC, useState } from 'react';

type Props = {
	prefixName: [number, string] | string;
	fieldName: [number, string] | string;
	initialValue?: {
		objectiveType: string;
		objectiveValue: number;
	};
	onChange?: (value: string) => void;
};

const ObjectiveTypeSelect: FC<Props> = ({
	initialValue,
	prefixName,
	fieldName,
	onChange,
}: Props) => {
	const handleSelectChange = (value: string) => {
		if (onChange) {
			onChange(value);
		}
	};

	const [objectiveType] = useState(initialValue?.objectiveType || 'budget');
	const [objectiveValue, setObjectiveValue] = useState(
		initialValue?.objectiveValue || 0
	);

	// const handleObjectiveTypeChange = (value: string) => {
	// 	setObjectiveType(value);
	// 	// Reinicia el valor de objetivo cuando cambia el tipo
	// 	setObjectiveValue(0);
	// };

	const prefixSelector = (
		<Form.Item
			name={prefixName}
			noStyle
			initialValue={objectiveType}
			rules={[
				{
					required: true,
					message: 'Please select a valid objective type',
				},
			]}>
			<Select
				style={{ width: 120 }}
				onChange={handleSelectChange}
				value={objectiveType}>
				<Select.Option value="budget">Budget</Select.Option>
				<Select.Option value="absolute">Absolute</Select.Option>
			</Select>
		</Form.Item>
	);

	return (
		<Form.Item
			name={fieldName}
			noStyle
			initialValue={objectiveValue}
			rules={[
				{
					required: true,
					message: 'Please input a valid objective value',
				},
			]}>
			<InputNumber
				addonBefore={prefixSelector}
				style={{ width: '100%' }}
				value={objectiveValue}
				onChange={(value) => setObjectiveValue(value as number)}
			/>
		</Form.Item>
	);
};

export default ObjectiveTypeSelect;
