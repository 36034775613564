import { UPDATE_PLATFORM_MUTATION } from '@/lib/reportsApi';
import { useMutation } from '@apollo/client';
import { Form, InputNumber, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { isNull } from 'lodash';
import { FC, useEffect } from 'react';

type Props = {
	spend: number;
	record: any;
	updateField: string;
	successMessage: string;
	refetch: any;
	prefix?: string;
};

const SpendCell: FC<Props> = ({
	spend,
	record,
	updateField,
	successMessage,
	refetch,
	prefix,
}: Props) => {
	const [updateReportPlatform, { loading, data, error }] = useMutation(UPDATE_PLATFORM_MUTATION);
	const handleOnBlur = async () => {
		const newSpend = form.getFieldValue(updateField);
		if (newSpend !== undefined && newSpend !== spend) {
			await updateReportPlatform({
				variables: { id: record.id, data: { [updateField]: newSpend } },
			});
			refetch();
		}
	};

	useEffect(() => {
		if (data) {
			message.success(successMessage);
		} else if (error) {
			message.error(error.message);
		}
	}, [data, error, successMessage]);

	const [form] = useForm();

	useEffect(() => {
		Object.keys(record).map((k) => {
			if (record[k] != form.getFieldValue(k)) {
				form.setFieldValue(k, record[k]);
			}
		});
	}, [record]);

	return (
		<Form form={form} className="pr-1">
			<Form.Item
				name={updateField}
				initialValue={spend}
				noStyle
				className="flex items-center justify-center">
				<InputNumber
					className="w-full"
					disabled={loading}
					size="middle"
					onBlur={handleOnBlur}
					prefix={prefix}
					onChange={(value) => form.setFieldValue(updateField, value)}
				/>
			</Form.Item>
		</Form>
	);
};

export default SpendCell;
