import { AuthContext } from '@/context/AuthContext';
import { Report } from '@/interfaces/Report';
import AuditClientModal from '@/routes/ClientServicesPage/components/AuditClientModal';
import DeleteCampaign from '@/routes/ClientServicesPage/components/DeleteCampaign';
import DuplicateCampaignModal from '@/routes/FinancesDataPage/DuplicateCampaignModal';
import EditCampaignModal from '@/routes/FinancesDataPage/EditCampaignModal';
import {
	CopyOutlined,
	DeleteOutlined,
	EditOutlined,
	HistoryOutlined,
	MoreOutlined,
} from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import { useContext, useState } from 'react';

type Props = {
	report: Report;
	refetch: any;
};

const CampaignActions = ({ report, refetch }: Props) => {
	const [open, setOpen] = useState('none');
	const { user } = useContext(AuthContext);

	const items: MenuProps['items'] = [
		{
			key: 'edit',
			icon: <EditOutlined />,
			label: 'Edit',
		},
		{
			key: 'duplicate',
			icon: <CopyOutlined />,
			label: 'Duplicate',
		},
		{
			key: 'audit',
			icon: <HistoryOutlined />,
			label: 'Audit',
		},
		...(user?.permission?.clientServicesDeleteCampaign === 'no'
			? []
			: [
					{
						key: 'delete',
						danger: true,
						icon: <DeleteOutlined />,
						label: 'Delete',
					},
			  ]),
	];

	return (
		<>
			<Dropdown
				arrow
				placement="bottom"
				trigger={['click']}
				menu={{
					items,
					onClick: (item) => {
						setOpen(item.key);
					},
				}}>
				<MoreOutlined style={{ cursor: 'pointer', fontSize: 18 }} />
			</Dropdown>
			<EditCampaignModal
				report={report}
				refetch={refetch}
				open={open === 'edit'}
				setOpen={setOpen}
			/>
			<DuplicateCampaignModal
				open={open === 'duplicate'}
				setOpen={setOpen}
				report={report}
				refetch={refetch}
			/>
			<AuditClientModal
				client={report}
				open={open === 'audit'}
				setOpen={setOpen}
			/>
			<DeleteCampaign
				open={open === 'delete'}
				setOpen={setOpen}
				reportId={report.id}
				refetch={refetch}
			/>
		</>
	);
};

export default CampaignActions;
