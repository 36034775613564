import { formatMoney } from '@/utils/currency';
import { Table } from 'antd';
type Props = {
	totals: {
		totalCost: number;
		totalMonthlyBilling: number;
		totalInvestment: number;
	};
};

const ReconciliationTableSummary = ({ totals }: Props) => {
	console.log(totals);
	const summaryColumns = [
		{ key: 'agency', render: null, fixed: true },
		{ key: 'client', render: null, fixed: true },
		{ key: 'campaign', render: null, fixed: true },
		{ key: 'datePeriod', render: null },
		{ key: 'ioOrPo', render: null },
		{ key: 'product', render: null },
		{ key: 'formats', render: null },
		{ key: 'businessModel', render: null },
		{ key: 'buyingType', render: null },
		{ key: 'onTvl', render: null },
		{
			key: 'investment',
			render: <span className="font-medium">{formatMoney(totals.totalInvestment)}</span>,
		},
		{ key: 'platform', render: null },
		{ key: 'platformName', render: null },
		{
			key: 'monthlyBilling',
			render: <span className="font-medium">{formatMoney(totals.totalMonthlyBilling)}</span>,
		},
		{
			key: 'Cost',
			render: <span className="font-medium">{formatMoney(totals.totalCost)}</span>,
		},
		{ key: 'comment', render: null },
	];
	return (
		<Table.Summary.Row className="bg-gray-50">
			{summaryColumns.map((c, index) => {
				if (index === 0) {
					return (
						<Table.Summary.Cell key={c.key} index={index} colSpan={4} align="left">
							<p className="my-0 text-right font-medium">Total</p>
						</Table.Summary.Cell>
					);
				} else if (index === 1 || index === 2 || index === 3) {
					return null;
				}
				return (
					<Table.Summary.Cell key={c.key} index={index} colSpan={1} align="left">
						{c.render}
					</Table.Summary.Cell>
				);
			})}
		</Table.Summary.Row>
	);
};

export default ReconciliationTableSummary;
