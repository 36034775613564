import { AuthContext } from '@/context/AuthContext';
import AuditModal from '@/routes/ClientServicesPage/components/AuditModal';
import DeletePlatform from '@/routes/ClientServicesPage/components/DeletePlatform';
import DuplicatePlatformModal from '@/routes/ClientServicesPage/components/DuplicatePlatformModal';
import EditPlatformModal from '@/routes/ClientServicesPage/components/EditPlatformModal';
import NotesModal from '@/routes/ClientServicesPage/components/NotesModal';
import {
	CopyOutlined,
	DeleteOutlined,
	EditOutlined,
	FileOutlined,
	HistoryOutlined,
	MoreOutlined,
} from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import { useContext, useState } from 'react';

type Props = {
	reportId: string;
	platform: any;
	refetch: () => void;
	refetchReports: () => void;
};

const PlatformActions = ({
	platform,
	refetch,
	reportId,
	refetchReports,
}: Props) => {
	const [open, setOpen] = useState('none');
	const { user } = useContext(AuthContext);

	const items: MenuProps['items'] = [
		{
			key: 'notes',
			icon: <FileOutlined />,
			label: 'Notes',
		},
		{
			key: 'edit',
			icon: <EditOutlined />,
			label: 'Edit',
		},
		{
			key: 'duplicate',
			icon: <CopyOutlined />,
			label: 'Duplicate',
		},
		{
			key: 'audit',
			icon: <HistoryOutlined />,
			label: 'Audit',
		},
		...(user?.permission?.clientServicesDeleteCampaign === 'no'
			? []
			: [
					{
						key: 'delete',
						danger: true,
						icon: <DeleteOutlined />,
						label: 'Delete',
					},
			  ]),
	];

	return (
		<>
			<Dropdown
				arrow
				placement="bottom"
				trigger={['click']}
				menu={{
					items,
					onClick: (item) => {
						setOpen(item.key);
					},
				}}>
				<MoreOutlined style={{ cursor: 'pointer', fontSize: 18 }} />
			</Dropdown>
			<EditPlatformModal
				platform={platform}
				refetch={refetch}
				open={open === 'edit'}
				setOpen={setOpen}
			/>
			<NotesModal
				open={open === 'notes'}
				setOpen={setOpen}
				notes={platform.notes}
				platform={platform}
				refetch={refetch}
			/>
			<AuditModal
				platform={platform}
				open={open === 'audit'}
				setOpen={setOpen}
			/>
			<DeletePlatform
				refetchReports={refetchReports}
				platformId={platform.id}
				refetch={refetch}
				open={open === 'delete'}
				setOpen={setOpen}
			/>
			<DuplicatePlatformModal
				refetchReports={refetchReports}
				reportId={reportId}
				platform={platform}
				refetch={refetch}
				open={open === 'duplicate'}
				setOpen={setOpen}
			/>
		</>
	);
};

export default PlatformActions;
