import { UPDATE_PLATFORM_MUTATION } from '@/lib/reportsApi';
import BuyingTypeAndRateField from '@/routes/DataPage/FormFields/BuyingTypeAndRateField';
import DevicesSelect from '@/routes/DataPage/FormFields/DevicesSelect';
import ObjectiveTypeSelect from '@/routes/DataPage/FormFields/ObjectiveTypeSelect';
import OtherVariablesSelect from '@/routes/DataPage/FormFields/OtherVariablesSelect';
import PlatformFormatsSelect from '@/routes/DataPage/FormFields/PlatformFormatSelect';
import SecondaryKPIField from '@/routes/DataPage/FormFields/SecondaryKPIField';
import { useMutation } from '@apollo/client';
import { Button, DatePicker, Form, Input, InputNumber, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import ReportingSelect from '@/routes/DataPage/FormFields/ReportingSelect';
import YesNoPendingSelect from '@/routes/DataPage/FormFields/YesNoPendingSelect';
import BusinessModelSelect from '@/routes/DataPage/FormFields/BusinessModelSelect';

type Props = {
	platform: any;
	refetch: () => void;
	open: boolean;
	setOpen: (newOpen: string) => void;
};

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 6 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 19 },
	},
};

const EditPlatformModal: FC<Props> = ({
	platform,
	refetch,
	open,
	setOpen,
}: Props) => {
	const [form] = useForm();
	const [maxDays, setMaxDays] = useState(platform.platformDays);

	const [objectiveType, setObjectiveType] = useState(platform.objectiveType);

	const [updateReportPlatform, { loading }] = useMutation(
		UPDATE_PLATFORM_MUTATION
	);

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		setOpen('none');
	};

	useEffect(() => {
		form.setFieldsValue({
			...platform,
			timePeriod: [
				dayjs(platform.startDate, 'YYYY-MM-DD'),
				dayjs(platform.endDate, 'YYYY-MM-DD'),
			],
		});
		setMaxDays(platform.platformDays);
		setObjectiveType(platform.objectiveType);
	}, [platform, form]);

	const onFinish = async (values: any) => {
		// console.log('Submitting form data:', values)

		const newFormats = values.platform.formats || [];
		const currentFormats = platform.formats || [];

		const formatsToDisconnect = currentFormats
			.filter((f: any) => !newFormats.includes(f.id))
			.map((f: any) => ({ id: f.id }));

		const formatsToConnect = newFormats
			.filter((f: any) => !currentFormats.some((cf: any) => cf.id === f))
			.map((f: any) => ({ id: f }));

		type FormatsType = {
			connect?: { id: any }[];
			disconnect?: { id: any }[];
		};

		const formats: FormatsType = {};
		if (formatsToConnect.length > 0) {
			formats.connect = formatsToConnect;
		}
		if (formatsToDisconnect.length > 0) {
			formats.disconnect = formatsToDisconnect;
		}

		if (platform.objectiveType !== 'absolute') {
			const aca = platform.rate;
			console.log(aca);
		} else {
			const aca = 'N/A for objective';
			console.log(aca);
		}

		const p = {
			startDate: values.timePeriod[0].format('YYYY-MM-DD'),
			endDate: values.timePeriod[1].format('YYYY-MM-DD'),
			platform: values.platform.platform
				? { connect: { id: values.platform.platform } }
				: null,
			formats: Object.keys(formats).length > 0 ? formats : undefined,
			name: values.name,
			businessModel: values.businessModel,
			fee: values.fee,
			platformDays: values.platformDays,
			activeDays: values.activeDays,
			devices: values.devices,
			otherVariables: values.otherVariables,
			buyingType: values.buyingType,
			rate: values.rate,
			// buyingType: values.buyingType === 'absolute' ? values.buyingType : 'CPM',
			// rate: values.rate === 'absolute' ? values.rate : 0,
			objectiveType: values.objectiveType,
			objectiveValue: values.objectiveValue,
			secondaryKPIType: values.secondaryKPIType,
			secondaryKPIValue: values.secondaryKPIValue,
			cost: values.cost,
			budget: values.budget,
			investment: values.investment,
			ctr: values.ctr,
			vtr: values.vtr,
			reporting: values.reporting,
			finalReport: values.finalReport,
			ioOrPo: values.ioOrPo,
			screenshots: values.screenshots,
			reconciliation: values.reconciliation,
		};

		console.log('Platform object to be sent:', p);

		try {
			const response = await updateReportPlatform({
				variables: { id: platform.id, data: p },
			});
			console.log('Update response:', response);
			refetch();
			setOpen('none');
		} catch (error) {
			console.error('Error occurred while updating platform:', error);
		}
	};

	const onFinishFailed = (errorInfo: any) => {
		console.error('Failed to submit form:', errorInfo);
	};

	const handleObjectiveTypeChange = (value: string) => {
		setObjectiveType(value);
		form.setFieldsValue({ objectiveType: value, rate: undefined });
	};

	return (
		<div>
			<Modal
				width={675}
				bodyStyle={{
					paddingTop: '20px',
					paddingRight: '10px',
					maxHeight: 'calc(100vh - 300px)',
					overflow: 'auto',
				}}
				title="Edit platform information"
				open={open}
				destroyOnClose={true}
				key={platform.id}
				onOk={handleOk}
				onCancel={handleCancel}
				keyboard
				footer={[
					<Button key="cancel" onClick={handleCancel} danger>
						Cancel
					</Button>,
					<Button
						form="editPlatform"
						key="submit"
						htmlType="submit"
						type="primary"
						loading={loading}>
						Submit
					</Button>,
				]}>
				<Form
					id="editPlatform"
					layout="horizontal"
					form={form}
					{...formItemLayout}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}>
					<Form.Item
						name="name"
						label="Name"
						rules={[{ required: true, message: 'Please add a name' }]}
						initialValue={platform.name}>
						<Input placeholder="Name" />
					</Form.Item>
					<Form.Item label="Platform & format">
						<PlatformFormatsSelect
							initialValue={{
								formats: platform.formats || undefined,
								platform: platform.platform || undefined,
							}}
							fieldName="platform"
						/>
					</Form.Item>
					<Form.Item
						name="businessModel"
						label="Business model"
						initialValue={platform.businessModel}
						rules={[
							{ required: true, message: 'Please add a business model' },
						]}>
						<BusinessModelSelect placeholder="Business Model" />
					</Form.Item>
					<Form.Item label="Fee" name="fee" initialValue={platform.fee}>
						<InputNumber min={0} style={{ width: '100%' }} />
					</Form.Item>
					<Form.Item
						label="Time period"
						name="timePeriod"
						rules={[
							{ required: true, message: 'Please select a valid date range' },
						]}
						initialValue={[
							dayjs(platform.startDate, 'YYYY-MM-DD'),
							dayjs(platform.endDate, 'YYYY-MM-DD'),
						]}>
						<DatePicker.RangePicker className="w-full" />
					</Form.Item>
					<Form.Item
						label="Platform days"
						name="platformDays"
						initialValue={platform.platformDays}>
						<InputNumber
							style={{ width: '100%' }}
							min={0}
							onChange={(value) => setMaxDays(value || 0)}
						/>
					</Form.Item>
					<Form.Item
						label="Platform active days"
						name="activeDays"
						tooltip="Active days must be lower or equal to platform days"
						initialValue={platform.activeDays}>
						<InputNumber
							style={{ width: '100%' }}
							min={0}
							max={maxDays}
							onChange={(value) =>
								form.setFieldValue('activeDays', value?.valueOf() as number)
							}
						/>
					</Form.Item>
					<Form.Item
						label="Devices"
						name="devices"
						initialValue={platform.devices}>
						<DevicesSelect
							placeholder="Devices"
							onChange={(value) => form.setFieldsValue({ devices: value })}
						/>
					</Form.Item>
					<Form.Item
						label="Other variables"
						name="otherVariables"
						initialValue={platform.otherVariables}>
						<OtherVariablesSelect
							placeholder="Other variables"
							onChange={(value) =>
								form.setFieldsValue({ otherVariables: value })
							}
						/>
					</Form.Item>
					<Form.Item
						label="Objective"
						required
						rules={[
							{ required: true, message: 'Please input a valid objective' },
						]}>
						<ObjectiveTypeSelect
							prefixName="objectiveType"
							fieldName="objectiveValue"
							initialValue={{
								objectiveType: platform.objectiveType,
								objectiveValue: platform.objectiveValue,
							}}
							onChange={handleObjectiveTypeChange}
						/>
					</Form.Item>

					{objectiveType === 'absolute' && (
						<Form.Item
							label="Buying Type & rate"
							required
							rules={[
								{ required: true, message: 'Please input a valid quantity' },
							]}>
							<BuyingTypeAndRateField
								initialValue={{
									buyingType: platform.buyingType,
									rate: platform.rate,
								}}
								fieldName="rate"
								prefixName="buyingType"
							/>
						</Form.Item>
					)}
					<Form.Item label="Secondary KPI">
						<SecondaryKPIField
							initialValue={{
								secondaryKPIType: platform.secondaryKPIType,
								secondaryKPIValue: platform.secondaryKPIValue,
							}}
							prefixName="secondaryKPIType"
							fieldName="secondaryKPIValue"
						/>
					</Form.Item>
					<Form.Item initialValue={platform.cost || 0} label="Cost" name="cost">
						<InputNumber addonBefore="$" style={{ width: '100%' }} min={0} />
					</Form.Item>
					<Form.Item
						initialValue={platform.budget || 0}
						label="Budget"
						name="budget">
						<InputNumber addonBefore="$" style={{ width: '100%' }} min={0} />
					</Form.Item>
					<Form.Item
						initialValue={platform.investment || 0}
						label="Investment"
						name="investment">
						<InputNumber addonBefore="$" style={{ width: '100%' }} min={0} />
					</Form.Item>
					<Form.Item initialValue={platform.ctr || 0} label="CTR%" name="ctr">
						<InputNumber min={0} style={{ width: '100%' }} />
					</Form.Item>
					<Form.Item initialValue={platform.vtr || 0} label="VTR%" name="vtr">
						<InputNumber min={0} style={{ width: '100%' }} />
					</Form.Item>
					<Form.Item
						initialValue={platform.reporting}
						label="Reporting"
						name="reporting">
						<ReportingSelect
							onChange={(value) => form.setFieldsValue({ reporting: value })}
							placeholder="Reporting"
						/>
					</Form.Item>
					<Form.Item
						initialValue={platform.finalReport}
						label="Final Report"
						name="finalReport">
						<YesNoPendingSelect
							onChange={(value) => form.setFieldsValue({ finalReport: value })}
							placeholder="Final Report"
						/>
					</Form.Item>
					<Form.Item
						initialValue={platform.ioOrPo}
						label="IO or PO"
						name="ioOrPo">
						<YesNoPendingSelect
							onChange={(value) => form.setFieldsValue({ ioOrPo: value })}
							placeholder="IO or PO"
						/>
					</Form.Item>
					<Form.Item
						initialValue={platform.screenshots}
						label="Screenshots"
						name="screenshots">
						<YesNoPendingSelect
							onChange={(value) => form.setFieldsValue({ screenshots: value })}
							placeholder="Screenshots"
						/>
					</Form.Item>
					<Form.Item
						initialValue={platform.reconciliation}
						label="Reconciliation"
						name="reconciliation">
						<YesNoPendingSelect
							onChange={(value) =>
								form.setFieldsValue({ reconciliation: value })
							}
							placeholder="Reconciliation"
						/>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default EditPlatformModal;
