import { Report } from '@/interfaces/Report';
import { DUPLICATE_REPORT_MUTATION, DuplicateReportVariables } from '@/lib/reportsApi';
import { useMutation } from '@apollo/client';
import { Checkbox, Divider, Input, Modal } from 'antd';
import { useState } from 'react';

type Props = {
	report: Report;
	refetch: any;
	open: boolean;
	setOpen: (newOpen: string) => void;
};

const DuplicateCampaignModal = ({ report, refetch, open, setOpen }: Props) => {
	const [newName, setNewName] = useState(report.campaign);
	const [duplicatePlatforms, setDuplicatePlatforms] = useState(false);

	const [duplicateReportModal, { loading }] = useMutation<Report, DuplicateReportVariables>(
		DUPLICATE_REPORT_MUTATION
	);

	const handleDuplicate = async () => {
		const data = await duplicateReportModal({
			variables: {
				id: report.id,
				duplicatePlatforms,
				newName,
			},
		});
		console.log(data);
		refetch();
		setOpen('none');
	};

	return (
		<Modal
			title="Duplicate Campaign"
			open={open}
			onCancel={() => setOpen('none')}
			onOk={handleDuplicate}
			confirmLoading={loading}>
			<p className="my-1">Are you sure you want to duplicate this campaign?</p>
			<Divider className="my-1 bg-gray-200" />
			<p className="mb-1 mt-0">
				<span className="font-semibold">GID:</span> {report.gluCampaignID}
			</p>
			<p className="mb-1 mt-0 flex gap-x-1">
				<div className="w-20 items-center font-semibold">Campaign:</div>
				<Input value={newName} onChange={(event) => setNewName(event.target.value)} size="small" />
			</p>
			<p className="mb-1 mt-0">
				<span className="font-semibold">Agency:</span> {report.agency.name}
			</p>
			<p className="mb-1 mt-0">
				<span className="font-semibold">Client:</span> {report.client.name}
			</p>
			{report.platforms.length > 0 && (
				<>
					<Divider className="my-1 bg-gray-200" />
					<p className="mb-1 mt-0">
						This campaign has {report.platforms.length} related platforms.
					</p>
					<Checkbox
						value={duplicatePlatforms}
						onChange={(event) => setDuplicatePlatforms(event.target.checked)}>
						Duplicate related platforms
					</Checkbox>
				</>
			)}
		</Modal>
	);
};

export default DuplicateCampaignModal;
