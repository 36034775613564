import { DeleteTwoTone, FileOutlined } from '@ant-design/icons';
import { Button, UploadFile } from 'antd';
import moment from 'moment';
import { FC } from 'react';

type Props = {
	file: UploadFile<any>;
	actions: {
		download: () => void;
		preview: () => void;
		remove: () => void;
	};
};

const UploadItem: FC<Props> = ({ file, actions }: Props) => {
	return (
		<div className="my-2 flex items-center justify-between rounded bg-slate-100 px-4 py-1">
			<div className="flex">
				<div className=" flex items-center rounded bg-slate-300 p-2">
					<FileOutlined style={{ fontSize: 32 }} />
				</div>
				<div className="ml-4">
					<span className=" text-base font-medium">{file.name.split('.')[0]}</span>
					<span className="text-xs font-light">.{file.name.split('.')[1]}</span>
					<p className="mb-1 mt-1 text-xs font-light">
						Last modified: {moment(file.lastModified).format('lll')}
					</p>
					<p className="my-1 text-xs font-medium text-slate-500">
						{Math.round((file.size || 0) / 1000)}KB
					</p>
				</div>
			</div>
			<div>
				<Button type="text" onClick={actions.remove}>
					<DeleteTwoTone style={{ fontSize: 24 }} twoToneColor="#ff0000" />
				</Button>
			</div>
		</div>
	);
};

export default UploadItem;
