import { gql } from '@apollo/client'
import { User } from '../interfaces/User'

export interface AuthenticatedUserData {
  authenticatedItem: User
}

export interface AuthenticateUserWithPasswordData {
  authenticateUserWithPassword: {
    __typename: string
  }
}
export interface AuthenticateUserWithPasswordVariables {
  email: string
  password: string
}

const AUTHENTICATED_USER = gql`
  query {
    authenticatedItem {
      ... on User {
        id
        name
        email
        country {
          id
          name
        }
        permission {
          id
          name
          seeAdminPanel
          rowDataSeeTable
          rowDataCreateCampaign
          rowDataImportCsv
          rowDataExportCsv
          rowDataUpdateCsv
          clientServicesSeePage
          clientServicesEditCampaign
          clientServicesDeleteCampaign
          clientServicesAddPlatform
          clientServicesEditPlatform
          clientServicesExportCsv
          financeResultsSeePage
          financeDataSeePage
          financeDataSeeTable
          financeDataEditCampaign
          reconciliationDataSeePage
          objetivesSeePage
          objetivesSeeTable
          manageObjetivesSeePage
          manageObjetivesSeeObjectives
          manageObjetivesCreateObjectives
        }
      }
    }
  }
`

const AUTHENTICATE_USER_WITH_PASSWORD = gql`
  mutation AUTHENTICATE_USER_WITH_PASSWORD(
    $email: String!
    $password: String!
  ) {
    authenticateUserWithPassword(email: $email, password: $password) {
      __typename
      ... on UserAuthenticationWithPasswordFailure {
        message
      }
    }
  }
`

const END_SESSION = gql`
  mutation {
    endSession
  }
`

export { AUTHENTICATED_USER, AUTHENTICATE_USER_WITH_PASSWORD, END_SESSION }
