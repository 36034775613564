import { buyingTypes } from '@/lib/constants';
import { Form, InputNumber, Select } from 'antd';
import { FC } from 'react';

type Props = {
	prefixName: [number, string] | string;
	fieldName: [number, string] | string;
	initialValue?: any;
};

const BuyingTypeAndRateField: FC<Props> = ({
	initialValue,
	prefixName,
	fieldName,
}: Props) => {
	const prefixSelector = (
		<Form.Item
			initialValue={initialValue?.buyingType}
			name={prefixName}
			rules={[{ required: true, message: 'Please input a valid buying type' }]}
			noStyle>
			<Select defaultActiveFirstOption style={{ width: 120 }}>
				{buyingTypes.map((type) => (
					<Select.Option key={type} value={type}>
						{type}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
	);

	return (
		<Form.Item
			initialValue={initialValue?.rate}
			noStyle
			name={fieldName}
			rules={[{ required: true, message: 'Please input a valid rate' }]}>
			<InputNumber addonBefore={prefixSelector} style={{ width: '100%' }} />
		</Form.Item>
	);
};

export default BuyingTypeAndRateField;
